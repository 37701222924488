
import { Watch, Mixins, Component } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import ETable from "@/components/ETable.vue";
import PeriodFormBase from "./component/periodForm.vue";
import PeriodDetail from "./component/periodDetail.vue";
import PeriodRecord from "./component/periodRecord.vue";

// import { IPeriodRecord } from "@/types/period";
import { IPeriodRecord } from "@/types/period"

import { cloneDeep } from "lodash"

import { periodList } from "@/api/period";

import { EPeriodStatus, EPeriodStatusDesc } from "@/enum/period.enum";

import { formatMoney } from "@/utils/common";

@Component({
    components: {
        ETable,
        PeriodFormBase,
        PeriodDetail,
        PeriodRecord,
    }
})
export default class PeriodIndex extends Mixins(loading, tablePagination, Resize) {
    EPeriodStatus = EPeriodStatus
    EPeriodStatusDesc = EPeriodStatusDesc
    queryForm = {
        deviceMessageCode: "",
        status: undefined,
        storeName: ""
    }
    
    periodStatusOptions: Array<{ val: string | number, name: string }> = [];
    tableData = [{ id: 1, deviceClassName: "1" }];

    columns = [
        { label: "序号", prop: "id", width: 70, align: "center" },
        { label: "门店名称", prop: "storeName", minWidth: 150, align: "center" },
        { label: "总金额", prop: "totalPrice", width: 120, align: "right"},
        { label: "剩余待还", prop: "remainingPrice", width: 120, align: "right" },
        { label: "分期期数", prop: "periodNum", width: 100, align: "center" },
        { label: "还款日", prop: "repaymentDate", minWidth: 180, align: "center" },
        { label: "还款人", prop: "repayment", minWidth: 200, align: "center" },
        { label: "分期状态", prop: "status", minWidth: 180, align: "center"},
        { label: "创建人", prop: "createdBy", minWidth: 150 , align: "center"},
        { label: "创建时间", prop: "createdTime", minWidth: 180, align: "center" },
        { label: "操作", prop: "action", fixed: "right", width: 200, align: "center" },
    ]

    periodFormVisible = false;
    periodDetailVisible = false;
    periodRecordVisible = false;

    periodInfo: IPeriodRecord = {};

    mounted() {
        this.getData();
        this.initStatusOptions();
        this.windowResize(300);
    }

    syncList() {
        this.getData();
    }

    initStatusOptions() {
        this.periodStatusOptions = Object.keys(this.EPeriodStatusDesc)?.filter(value => isNaN(Number(value)))?.map((key, index) => {
            return { val: index, name: EPeriodStatusDesc[index] }
        })
    }

    search() {
        this.resetCurrent();
        this.getData();
    }

    getData() {
      const params = {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          ...this.queryForm
      }
      this.showLoading();
      periodList(params).then((res) => {
        res.data.list?.forEach((record) => {
            const { imgList, deviceList, userList } = record;
            record.imgList = imgList?.map(img => ({ url: img.imgUrl, id: img.id }));

            record.selectDeviceDescList = deviceList;
            record.deviceList = deviceList?.map(el => el.id);

            record.userDescList = userList;
            record.userList = userList?.map(el => el.id)

            record.remainingPrice = formatMoney(record.remainingPrice);
            record.totalPrice = formatMoney(record.totalPrice);

        })
        this.tableData = res.data.list;
        this.itemTotal = res.data.total;
      }).finally(() => {
        this.hideLoading();
      })
    }

    addPeriod() {
        this.periodInfo = {}
        this.periodFormVisible = true
    }

    edit(row: IPeriodRecord) {
        this.periodInfo = cloneDeep(row)
        this.periodFormVisible = true;
    }

    detail(row: IPeriodRecord) {
        this.periodInfo = cloneDeep(row)
        this.periodDetailVisible = true;
    }

    record(row: IPeriodRecord) {
        this.periodInfo = cloneDeep(row)
        this.periodRecordVisible = true;
    }
}
