// 0-未开始,1-待支付（还款日5天内，不包括当天）,2-本期已付清,3-已完成，4-存在逾期
export enum EPeriodStatus {
    NO_START,
    WAIT_PAY,
    DONE_SETTLE,
    DONE,
    OVERDUE,
}

export enum EPeriodStatusDesc {
    "未开始",
    "待支付",
    "本期已付清",
    "已完成",
    "存在逾期",
}

// 0-未开始，1-待支付（还款日5天内，不包括当天），2-已完成，3-已逾期

export enum EPeriodPlantStatus {
    NO_START,
    WAIT_PAY,
    DONE,
    OVERDUE
}

export enum EPeriodPlantStatusDesc {
    "未开始",
    "待支付",
    "已完成",
    "已逾期"
}