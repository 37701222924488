
import { Component,Ref,Vue,Prop,Model } from 'vue-property-decorator';
import { truncateDecimal } from "@/utils/common";

/**
 example: <EMoney v-model="money" />
 */

@Component
export default class EMoney extends Vue {
    @Ref("elInputRef") elInputRef;
    @Model('input',{ type: String }) readonly value!: string;

    private internalValue: string = '';

    get formattedValue(): string {
        return this.formatCurrency(this.internalValue);
    }

    set formattedValue(newValue: string) {
        this.internalValue = this.parseCurrency(newValue);
    }

    mounted() {
        this.internalValue = this.value;
    }

    formatCurrency(value: string): string {
        if (!value) return '';
        // 解析输入的值，去除千分位分隔符
        const num = parseFloat(value.replace(/,/g, ''));
        if (isNaN(num)) return '';

        // 保留两位小数，并格式化为千分位形式
        const [integerPart, decimalPart] = num.toFixed(2).split('.');
        const formatted = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return decimalPart ? `${formatted}.${decimalPart.slice(0, 2)}` : formatted;
    }

    parseCurrency(value: string): string {
        return value.replace(/,/g,'');
    }

    onInput(event) {
        const input = this.elInputRef.$el?.querySelector(".el-input__inner")
        const start = input.selectionStart;
        const end = input.selectionEnd;

        const valueBeforeChange = input.value;
        const parsedValue = this.parseCurrency(valueBeforeChange);
        this.internalValue = parsedValue;
        this.$emit('input',parsedValue);

        // 等待 Vue 更新 DOM
        this.$nextTick(() => {
            const formattedValue = this.formatCurrency(parsedValue);
            input.value = formattedValue;
            const newCursorPos = this.getNewCursorPos(valueBeforeChange,formattedValue,start,end);
            input.setSelectionRange(newCursorPos,newCursorPos);
        });
    }

    onBlur(event: Event) {
        const input = event.target as HTMLInputElement;
        input.value = this.formatCurrency(input.value);
    }

    onFocus(event: Event) {
        const input = event.target as HTMLInputElement;
        input.value = this.formatCurrency(this.internalValue);
    }

    getNewCursorPos(before: string,after: string,start: number,end: number): number {
        const beforeCommas = (before.slice(0,start).match(/,/g) || []).length;
        const afterCommas = (after.slice(0,start).match(/,/g) || []).length;
        console.log(beforeCommas)
        console.log(afterCommas)
        return start + (afterCommas - beforeCommas);
    }
}
