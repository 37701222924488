
import { Component,Vue, Ref, PropSync, InjectReactive, Prop, Watch } from 'vue-property-decorator';
import { Transfer } from 'element-ui';

import { storeRelevanceDevice } from "@/api/period";

import { cloneDeep } from "lodash"

@Component
export default class PeriodFormTransformBase extends Vue {
    @PropSync("form") baseForm: any;
    @Ref("transferRef") transferRef!: Transfer;

    @PropSync("visible") showTransfer: boolean;
    @Prop() storeId !: number | string;

    recuperateList = [
    ];

    selectDeviceListChange($event: Array<number>) {
      this.baseForm.selectDeviceDescList = $event?.map((el) => this.recuperateList?.find((re) => re.id == el));
    }

    addRecuperate() {
        this.transferRef?.clearQuery("left");
        this.transferRef?.clearQuery("right");
    }

    closeTransfer() {
        this.showTransfer = false;
    }
    confirmTransfer() {
        this.closeTransfer();
    }

    @Watch("baseForm.storeId", { deep: true, immediate: true })
    getStoreRelevanceDevice(storeId) {
      console.log("getStoreRelevanceDevice")
      if (!storeId) return;
      storeRelevanceDevice(storeId).then((res) => {
        this.recuperateList = res.data || [];
        this.recuperateList?.forEach(el => {
          el.deviceMessageName = `${el.deviceName}-${el.deviceMessageCode}`
        })
      })
    }
}
