
import { Watch, Prop,Component,Vue,Ref, ProvideReactive,PropSync } from "vue-property-decorator";

import UploadImg from "@/components/uploadImg.vue";
import { storeList } from "@/api/store";
import { userList } from "@/api/serviceProvider"

import { Form } from "element-ui";
import DeviceTransfer from "./deviceTransfer.vue";
import dayjs from "dayjs";

import { debounce } from "lodash";

import { periodCreateOrUpdate, periodUpdateImg, getStoreManager } from "@/api/period";
import EMoney from "@/components/EMoney.vue";
import { parseNumber } from "@/utils/common";

@Component({
    components: { UploadImg,DeviceTransfer, EMoney }
})
export default class PeriodFormBase extends Vue {
    @Ref("baseFormRef") baseFormRef !: Form;
    @Ref("deviceTransferRef") DeviceTransferRef !: DeviceTransfer;

    @PropSync("visible") periodFormVisible !: boolean;
    @Prop({}) private periodInfo !: any;

    stepActive = 0;

    form = {
        id: "",
        storeId: "",
        storeName: "",
        totalPrice: undefined,
        firstPrice: undefined,
        imgList: [],
        deviceList: [], // 只存储id
        selectDeviceDescList: [],
        userList: [], // 只存储id
        userDescList: [],
        periodNum: undefined,
        planList: [],
    };

    saveLoading = false;

    rules = {
        storeId: [{ required: true, message: "请选择门店" }],

        deviceList: [{ required: true, message: "请选择调理设备" }, { type: "array" }],

        firstPrice: [{ required: true,message: "请输入首付金额" }],
        totalPrice: [{ required: true,message: "请输入首付金额" }],

        userList: [{ required: true, message: "请选择付款人员" }, { type: "array" }],

        // imgList: [{ required: true,message: "请上传合同图片" },{ type: "array" }],

        repaymentDate: [{ required: true,message: "请选择还款日期" }],
        periodPrice: [{ required: true,message: "请输入分期金额" }],

        periodNum: [{ required: true,message: "请选择分期期数" }]
    }

    storeSelectLoading = false;
    allStoreList = [];

    userSelectLoading = false;
    allUserList = [];

    periodNumOptions = [
        { label: "3期",value: 3 },
        { label: "6期",value: 6 },
        { label: "12期",value: 12 },
        { label: "18期",value: 18 },
        { label: "24期",value: 24 },
        { label: "36期",value: 36 },
    ]

    pickerOptions = {
        disabledDate(time) {
            // 获取今天的日期，并将时分秒设置为零
            const today = new Date();
            today.setHours(0,0,0,0);
            // 比较日期，如果时间小于今天，则返回 true 表示禁用
            return time.getTime() <= today.getTime();
        }
    }

    transferVisible = false

    getStoreRemote = debounce(function (str) {
        this.storeSelectLoading = true;
        storeList({ storeName: str,pageNum: 1,pageSize: 500 }).then((res) => {
            this.storeSelectLoading = false;
            this.allStoreList = res.data && res.data.list;
        }).catch((err) => { });
    })


    getStoreList(str) {
        if (typeof str == "object") {
            str = "";
        }
        this.getStoreRemote(str)
    }

    getStoreManager(storeId) {
        getStoreManager(storeId).then((res) => {
            this.allUserList = res.data;
        })
    }

    @Watch("form.periodNum")
    setplanList(val: number) {
        if (!val || this.form.id) return;
        this.form.planList = new Array(val).fill("")?.map((el,index) => ({ periodOrder: `${index + 1}`,repaymentDate: undefined,periodPrice: undefined }));
    }

    storeIdChange(storeId: number) {
        this.form.storeName = this.allStoreList?.find(store => store.id == storeId)?.storeName;
        this.form.deviceList = [];
        this.form.selectDeviceDescList = [];
        this.form.userList = [];
        if (!storeId) return;
        this.getStoreManager(storeId);
    }

    userListChange($event: Array<number>) {
        if (!$event) return;
        this.form.userDescList = $event?.map((id) => {
            return this.allUserList?.find((user) => user.id == id)
        })
    }

    repaymentDateChange($event) {
        this.form.planList?.forEach((row, index) => {
            row.repaymentDate = $event;
            if ($event) {
                row.repaymentDate = dayjs(row.repaymentDate).add(index, "month").format("YYYY-MM-DD")
            }
        })
    }

    selectRecuperate() {
        this.transferVisible = true;
        this.DeviceTransferRef?.addRecuperate();
    }

    closeForm() {
        this.periodFormVisible = false;
        this.baseFormRef?.resetFields();
        this.form.deviceList = [];
        this.form.selectDeviceDescList = [];
        this.form.planList = [];
        this.form.id = "";
    }

    save() {
        this.baseFormRef.validate().then(() => {
            this.$confirm('请确认填写信息的正确性，保存后无法修改，确认无误？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.doSave();
            })
        })
    }

    doSave() {
        const { id, storeId, storeName, totalPrice, firstPrice, periodNum,
        imgList,
        deviceList,
        selectDeviceDescList,
        userList,
        userDescList,
        planList} = this.form;
        const params = !id ? {
            storeId, storeName,
            totalPrice: parseNumber(totalPrice), 
            firstPrice: parseNumber(firstPrice), 
            periodNum,
            imgList: imgList?.map((el) => ({ imgUrl: el.url })),
            deviceList: selectDeviceDescList?.map((el) => ({ deviceId: el.id, deviceMessageCode: el.deviceMessageCode, deviceName: el.deviceName })),
            userList: userDescList?.map((el) => ({ userId: el.id, userName: el.nickname, userPhone: el.phone })),
            planList: planList?.map((el) => ({ ...el, periodPrice: parseNumber(el.periodPrice) }))
        } : {
            id,
            imgList: imgList?.map((el) => ({ id: el.id, imgUrl: el.url })),
        }
        id ? this.updatePeriod(params) : this.createPeriod(params);
    }

    createPeriod(params) {
        periodCreateOrUpdate(params).then((res) => {
            this.closeForm()
            this.$emit("syncList")
        })
    }

    updatePeriod(params) {
        periodUpdateImg(params).then((res) => {
            this.closeForm()
            this.$emit("syncList")
        })
    }

    @Watch("periodInfo", { deep: true, immediate: true })
    echoForm(value) {
        if (!value) return;
        Object.assign(this.form, value || {})
    }
}
