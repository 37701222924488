
import { Vue, Component, PropSync, Prop, Watch } from "vue-property-decorator";

import { periodLogInfo } from "@/api/period";

import ETable from "@/components/ETable.vue";
import elImageViewer from "element-ui/packages/image/src/image-viewer.vue";

@Component({
    components: { ETable, elImageViewer }
})
export default class PeriodRecord extends Vue {
    @PropSync("visible") periodRecordVisible !: boolean;
    @Prop([String]) private storeName!: string;
    @Prop({}) private periodInfo !: any;

    tableData = [];

    previewVisible = false;
    viewRecord = {
        imgUrl: "",
    }

    columns = [
        { label: "编辑人", prop: "modifiedBy", align: "center" },
        { label: "编辑时间", prop: "modifiedTime", align: "center" },
        { label: "操作动作", prop: "status", align: "center"},
        { label: "编辑内容", prop: "action", align: "center"},
    ]

    get previewSrcList() {
        return [this.viewRecord.imgUrl]
    }

    viewList(row) {
        this.viewRecord = row;
        this.previewVisible = true
    }

    closePreview() {
        this.previewVisible = false
    }

    close() {
        this.periodRecordVisible = false;
    }

    periodLogInfoList() {
        periodLogInfo(this.periodInfo.id).then((res) => {
            this.tableData = res.data || [];
        })
    }

    @Watch("periodInfo", { deep: true, immediate: true })
    echoForm(value) {
        if (!value.id) return;
        this.periodLogInfoList();
    }

}
