
import { Vue, Component, PropSync, Prop, Watch } from "vue-property-decorator";

import ETable from "@/components/ETable.vue";

import { EPeriodStatus, EPeriodStatusDesc, EPeriodPlantStatus, EPeriodPlantStatusDesc } from "@/enum/period.enum";
import { formatMoney } from "@/utils/common";

@Component({
    components: { ETable }
})
export default class PeriodDetail extends Vue {
    @PropSync("visible") periodDetailVisible !: boolean;
    @Prop([String]) private storeName!: string;
    @Prop({}) private periodInfo !: any;

    formatMoney = formatMoney;

    EPeriodStatus = EPeriodStatus;
    EPeriodPlantStatus = EPeriodPlantStatus;
    EPeriodPlantStatusDesc = EPeriodPlantStatusDesc;

    staticAllData = [];

    tableData = [];

    columns = [
        { label: "期数", prop: "period", width: 70, align: "center" },
        { label: "还款日期", prop: "repaymentDate", minWidth: 150, align: "center" },
        { label: "订单号", prop: "payOrderNo", minWidth: 150, align: "center" },
        { label: "状态", prop: "statusDesc", width: 120, align: "center" },
        { label: "还款金额", prop: "periodPrice", width: 120, align: "right" },
        { label: "支付时间", prop: "payTime", width: 100, align: "center" },
        { label: "用户ID", prop: "userId", width: 80, align: "center" },
        { label: "用户昵称", prop: "userName", width: 100, align: "center" },
        { label: "支付手机", prop: "userPhone", minWidth: 110, align: "center" },
        { label: "支付方式", prop: "payType", minWidth: 100, align: "center" },
        { label: "支付流水号", prop: "paySerialNumber", minWidth: 180, align: "center" },
    ]

    close() {
        this.periodDetailVisible = false;
    }

    @Watch("periodInfo", { deep: true, immediate: true })
    echoTableList(value) {
        if (!value) return;
        this.staticAllData = this.periodInfo?.planList;
        this.tableData = this.staticAllData;
    }

}
