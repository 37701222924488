import { service } from '@/ajax/request'

import { IPeriodRecordParams } from "@/types/period";

export const periodList = (params: IPeriodRecordParams) => (
  service({
    url: '/period-store/list',
    method: 'POST',
    params,
  })
)

export const periodCreateOrUpdate = (params) => (
  service({
    url: '/period-store/createOrUpdate',
    method: 'POST',
    params,
  })
)


export const periodUpdateImg = (params) => (
  service({
    url: '/period-store/updateImgs',
    method: 'POST',
    params,
  })
)


export const storeRelevanceDevice = (storeId: number | string) => (
  service({
    url: `/period-store/device/${storeId}`,
    method: 'POST',
  })
)

export const periodLogInfo = (periodId) => (
  service({
    url: `/period-store/infoLog/${periodId}`,
    method: 'POST',
  })
)

export const getStoreManager = (storeId) => (
  service({
    url: `/period-store/manager/${storeId}`,
    method: 'POST',
  })
)

